import React, { Component } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import parse from "html-react-parser";
//component
import ContentWrapper from "../component/common/chunk/contentWrapper";
import Wrapper from "../component/common/chunk/wrapper";
import Footer from "../component/common/footer";
import Userprofilenav from "../component/common/userprofilenav";
//constants
import withNavigateHook from "../component/withNavigateHook";
import Endpoints from "../constants/endpoints";
import { VALIDATE_TOKEN } from '../constants/validation';

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timerProgressBar: true,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
});

const isEmail = (email) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
const isPassword = (password) => /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,30}$/i.test(password);
const passwordErrorMsg = `<p class="bg-danger">**Password is invalid<br/>
**Password length must be atleast 8 characters<br/>
**Password length must not exceed 30 characters<br/>
**Password must contain atleast an UPPERCASE letter (A-Z)<br/>
**Password must contain atleast an lowercase letter (a-z)<br/>
**Password must contain atleast an number (0-9)<br/>
**Password must contain atleast a special character (@, $, !, &, etc).
</p>`
class UserProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: {},
            username: '',
            profilePhoto: {},
            oldPassword: '',
            newPassword: '',
            newPasswordRepeat: '',
            currentEmail: '',
            newEmail: '',
            password: '',
            errors: {
                cpEr: null,
                npEr: null,
                nprEr: null,
                neEr: null,
                ceEr: null
            }
        }
        this.profilePhotoRef = React.createRef();
    }

    authUser() {
        const userData = JSON.parse(localStorage.getItem('userData'));
        const token = localStorage.getItem('token');
        if (token === null || token === undefined || token.trim() === '') {
            this.props.navigation('/');
            window.location.reload(false);
        } else {
            if (userData) {
                VALIDATE_TOKEN(token, (valid) => {
                    if (valid.statusCode !== 0) {
                        localStorage.setItem('token', '');
                        this.props.navigation('/');
                        window.location.reload(false);
                    }
                });
            } else {
                localStorage.setItem('token', '');
                this.props.navigation('/');
                window.location.reload(false);
            }
        }
    }

    resetProfilePhoto() {
        this.profilePhotoRef.current.value = null;
    }

    fetchUserDetails() {
        fetch(Endpoints.get_user_details, {
            method: "GET",
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token'),
                Accept: "application/json"
            }
        })
            .then((res) => res.json())
            .then((userDetails) => {
                localStorage.removeItem('userData');
                localStorage.setItem('userData', JSON.stringify(userDetails));
                this.setState({
                    userData: userDetails.user,
                    username: userDetails.user.username
                });
            }).catch(err => {
                console.log(`error occured while fetching user details. -> ${err}`);
                Toast.fire({
                    icon: "error",
                    title: err.message
                });
            });
    }

    handleProfilePhotoUpload(e) {
        e.preventDefault();
        const formData = new FormData();
        formData.append('profilePhoto', this.state.profilePhoto);

        fetch(Endpoints.upload_profile_photo, {
            method: "POST",
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token'),
                Accept: "application/json",
            },
            body: formData
        }).then((res) => res.json())
            .then((response) => {
                this.resetProfilePhoto();
                if (response.statusCode === 0) {
                    Toast.fire({
                        icon: 'success',
                        title: response.message
                    });
                } else {
                    Toast.fire({
                        icon: 'error',
                        title: response.message
                    });
                }
                this.fetchUserDetails();
            }).catch(err => {
                console.log(`error occured while uploading new profile photo. -> ${err}`);
                Toast.fire({
                    icon: "error",
                    title: err.message
                });
            });
    }

    handleChangePassword(e) {
        e.preventDefault();
        //create password change request
        const { username, oldPassword, newPassword } = this.state;
        if (oldPassword === '' || newPassword === '') {
            this.setState({
                errors: {
                    npEr: passwordErrorMsg,
                    nprEr: passwordErrorMsg
                }
            });
            return;
        }
        const request = JSON.stringify({
            username: username,
            oldPassword: oldPassword,
            newPassword: newPassword
        });
        fetch(Endpoints.change_password, {
            method: "POST",
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token'),
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: request
        }).then((res) => res.json())
            .then((resp) => {
                if (resp.statusCode === 0) {
                    Toast.fire({
                        icon: "success",
                        title: resp.message
                    });
                } else {
                    Toast.fire({
                        icon: "error",
                        title: resp.message
                    });
                }
            }).catch(err => {
                console.log(`error changing password -> ${err}`);
                Toast.fire({
                    icon: "error",
                    title: err.message
                });
            });
    }

    handleEmailChange(e) {
        e.preventDefault();
        //create email change request
        const { username, newEmail, password } = this.state;
        if (newEmail === '') {
            this.setState({ errors: { neEr: 'Enter a valid email' } });
            return;
        }

        const request = JSON.stringify({
            username: username,
            newEmail: newEmail,
            password: password
        });
        fetch(Endpoints.change_email, {
            method: "POST",
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token'),
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: request
        }).then((res) => res.json())
            .then((resp) => {
                if (resp.statusCode === 0) {
                    this.fetchUserDetails();
                    Toast.fire({
                        icon: "success",
                        title: resp.message
                    });
                } else {
                    Toast.fire({
                        icon: "error",
                        title: resp.message
                    });
                }
            }).catch(err => {
                console.log(`error changing email -> ${err}`);
                Toast.fire({
                    icon: "error",
                    title: err.message
                });
            });
    }

    emailValidation() {
        const { currentEmail, newEmail } = this.state;
        if (!isEmail(currentEmail)) {
            this.setState({ errors: { ceEr: 'Enter a valid email' } });
        } else {
            this.setState({ errors: { ceEr: null } });
        }

        if (!isEmail(newEmail)) {
            this.setState({ errors: { neEr: 'Enter a valid email' } });
        } else {
            this.setState({ errors: { neEr: null } });
        }
    }

    passwordValidation() {
        const { newPassword, newPasswordRepeat } = this.state;
        if (!isPassword(newPassword)) {
            this.setState({ errors: { npEr: passwordErrorMsg } });
        } else {
            this.setState({ errors: { nprEr: null } });
        }

        if (!isPassword(newPasswordRepeat)) {
            if (this.state.npEr !== null) {
                this.setState({ errors: { nprEr: passwordErrorMsg } });
            }
        } else {
            this.setState({ errors: { nprEr: null } });
        }

        if (isPassword(newPassword) && isPassword(newPasswordRepeat)) {
            this.setState({ errors: { nprEr: null, nprEr: null } });
            //check if password equal
            if (newPassword !== newPasswordRepeat) {
                this.setState({
                    errors: {
                        npEr: 'password does not match',
                        nprEr: 'password does not match'
                    }
                });
            } else {
                this.setState({
                    errors: {
                        npEr: null,
                        nprEr: null
                    }
                });
            }
        }
    }

    componentDidMount() {
        //validate token
        this.authUser();
        //fetch user details
        this.fetchUserDetails();

        // add class to body element
        document.body.classList.add("hold-transistion");
        document.body.classList.add("sidebar-collapse");
        document.body.classList.add("layout-top-nav");
    }

    componentWillUnmount() {
        //remove styles when component unmounts
        document.body.classList.remove("hold-transistion");
        document.body.classList.remove("sidebar-collapse");
        document.body.classList.remove("layout-top-nav");
    }

    render() {
        const { userData, errors } = this.state;
        return (
            <Wrapper>
                <Userprofilenav />
                <ContentWrapper>
                    <section className="content-header">
                        <div className="container">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1>Profile</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item">
                                            <Link to={"/dashboard"}>Home</Link>
                                        </li>
                                        <li className="breadcrumb-item active">User Profile</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="content">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="card card-primary card-outline">
                                        {Object.keys(userData) === 0 ? '' :
                                            <div className="card-body box-profile">
                                                <div className="text-center">
                                                    <img className="profile-user-img img-fluid img-circle"
                                                        src={`/images/uploads/${userData.profile_photo === null ? 'avatar.png' : userData.profile_photo}`}
                                                        alt="User profile picture" />
                                                </div>
                                                <h3 className="profile-username text-center">
                                                    {userData.last_name + ' ' + userData.first_name}
                                                </h3>
                                                <p className="text-muted text-center">{userData.job_role}</p>
                                                <ul className="list-group list-group-unbordered mb-3">
                                                    <li className="list-group-item">
                                                        <b>username</b> <a className="float-right">{userData.username}</a>
                                                    </li>
                                                    <li className="list-group-item">
                                                        <b>Email</b> <a className="float-right">{userData.email}</a>
                                                    </li>
                                                    <li className="list-group-item">
                                                        <b>Password Change Date</b> <a className="float-right">{userData.password_change_date}</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <div className="card card-primary card-outline">
                                        <div className="card-header p-2">
                                            <h4 className="text-primary text-center">Profile Settings</h4>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-sm-4">Upload Profile Picture</div>
                                                <div className="col-sm-8">
                                                    <form className="form-inline">
                                                        <div className="form-group row">
                                                            <div className="col-sm-9">
                                                                <input type="file"
                                                                    name="profilePhoto"
                                                                    ref={this.profilePhotoRef}
                                                                    className="pb-1 form-control-file form-control-sm"
                                                                    style={{ border: 'black', borderRadius: '5px' }}
                                                                    onChange={e => { this.setState({ profilePhoto: e.target.files[0] }) }}
                                                                />
                                                            </div>
                                                            <div className="col-sm-3">
                                                                <button className="btn btn-block btn-sm btn-primary"
                                                                    onClick={e => { this.handleProfilePhotoUpload(e) }}
                                                                >
                                                                    Upload
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="row">
                                                <div className="col-sm-4">Password</div>
                                                <div className="col-sm-8">
                                                    <a href="#" data-target="#changePasswordModal"
                                                        data-toggle="modal" style={{ textDecoration: 'underline' }}>
                                                        Change Password
                                                    </a>
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="row">
                                                <div className="col-sm-4">Email</div>
                                                <div className="col-sm-8">
                                                    <a href="#" data-target="#changeEmailModal"
                                                        data-toggle="modal"
                                                        style={{ textDecoration: 'underline' }}>
                                                        Change Email
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </ContentWrapper>
                <Footer />
                <aside className="control-sidebar control-sidebar-dark"></aside>

                <div className="modal fade" id="changePasswordModal">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">TheMiddleStump - Change Password</h4>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form>
                                    {errors.cpEr && <span style={{ color: 'red' }}>{parse(errors.cpEr)}</span>}
                                    {errors.npEr && <span style={{ color: 'red' }}>{parse(errors.npEr)}</span>}
                                    {errors.nprEr && <span style={{ color: 'red' }}>{parse(errors.nprEr)}</span>}
                                    <div className="row mb-2">
                                        <div className="col-sm-4 text-right">Current Password :</div>
                                        <div className="col-sm-8 text-primary">
                                            <input type="password"
                                                className="form-control form-control-sm"
                                                required
                                                value={this.state.oldPassword}
                                                onChange={e => { this.setState({ oldPassword: e.target.value }) }}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-sm-4 text-right">New Password :</div>
                                        <div className="col-sm-8 text-primary">
                                            <input type="password"
                                                className="form-control form-control-sm"
                                                required
                                                value={this.state.newPassword}
                                                onChange={e => { this.setState({ newPassword: e.target.value }); this.passwordValidation(); }}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-1">
                                        <div className="col-sm-4 text-right">New Password Repeat :</div>
                                        <div className="col-sm-8 text-primary">
                                            <input type="password"
                                                className="form-control form-control-sm"
                                                required
                                                value={this.state.newPasswordRepeat}
                                                onChange={e => { this.setState({ newPasswordRepeat: e.target.value }); this.passwordValidation(); }}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-8 offset-sm-4 text-primary">
                                            <button type="submit"
                                                className="btn btn-sm btn-block btn-primary"
                                                data-dismiss="modal"
                                                onClick={e => { this.handleChangePassword(e) }}
                                            >
                                                Change password
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="changeEmailModal">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">TheMiddleStump - Change Email</h4>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form>
                                    <div className="row mb-2">
                                        <div className="col-sm-4 text-right">Current Email :</div>
                                        <div className="col-sm-8 text-primary">
                                            <input type="text"
                                                className="form-control form-control-sm"
                                                required
                                                value={this.state.currentEmail}
                                                onChange={e => { this.setState({ currentEmail: e.target.value }); this.emailValidation(); }}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-sm-4 text-right">New Email :</div>
                                        <div className="col-sm-8 text-primary">
                                            <input type="text"
                                                className="form-control form-control-sm"
                                                required
                                                value={this.state.newEmail}
                                                onChange={e => { this.setState({ newEmail: e.target.value }); this.emailValidation(); }}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-sm-4 text-right">Password :</div>
                                        <div className="col-sm-8 text-primary">
                                            <input type="password"
                                                className="form-control form-control-sm"
                                                required
                                                value={this.state.password}
                                                onChange={e => { this.setState({ password: e.target.value }) }}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-1">
                                        <div className="col-sm-8 offset-sm-4 text-primary">
                                            <button type="submit"
                                                className="btn btn-sm btn-block btn-primary"
                                                data-dismiss="modal"
                                                onClick={e => { this.handleEmailChange(e) }}
                                            >
                                                Change email
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Wrapper>
        )
    }
}

export default withNavigateHook(UserProfile);