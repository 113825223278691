import React, { Component } from 'react';
import DataTables from 'react-data-table-component';
//component
import Wrapper from '../component/common/chunk/wrapper';
import ContentWrapper from '../component/common/chunk/contentWrapper';
import HeaderBreadcrumb from '../component/common/chunk/headerbreadcrumb';
import Footer from '../component/common/footer';
import Nav from '../component/common/nav';
import SideMenu from '../component/common/sidemenu';
import Endpoints from '../constants/endpoints';
import { VALIDATE_TOKEN } from '../constants/validation';
import withNavigateHook from '../component/withNavigateHook';

const columns = [
    { name: 'Title', selector: row => row.title, sortable: true },
    {
        name: 'Action', button: true,
        sortable: true
    }
];

class DeleteArticle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            articleList: []
        }
    }

    authUser() {
        const userData = JSON.parse(localStorage.getItem('userData'));
        const token = localStorage.getItem('token');
        if (token === null || token === undefined || token.trim() === '') {
            this.props.navigation('/');
            window.location.reload(false);
        } else {
            if (userData) {
                VALIDATE_TOKEN(token, (valid) => {
                    if (valid.statusCode !== 0) {
                        localStorage.setItem('token', '');
                        this.props.navigation('/');
                        window.location.reload(false);
                    } else {
                        fetch(Endpoints.pos_tags, {
                            method: "GET",
                            headers: {
                                Authorization: 'Bearer ' + localStorage.getItem('token'),
                                Accept: "application/json"
                            }
                        })
                            .then((res) => res.json())
                            .then((postTags) => {
                                this.setState({ tags: postTags });
                            }).catch(err => {
                                console.log(`error fetching post tags -> ${err}`);
                            });
                    }
                });
            } else {
                localStorage.setItem('token', '');
                this.props.navigation('/');
                window.location.reload(false);
            }
        }
    }

    componentDidMount() {
        //validate token
        this.authUser();

        //fetch all articles
        fetch(Endpoints.get_all_articles)
            .then((res) => res.json())
            .then((data) => {
                this.setState({ articleList: data });
            }).catch(err => {
                console.log(`error retrieving article list -> ${err}`);
            });

        // add class to body element
        document.body.classList.add("hold-transistion");
        document.body.classList.add("sidebar-mini");
        document.body.classList.add("layout-fixed");
        document.body.classList.add("layout-navbar-fixed");
        document.body.classList.add("layout-footer-fixed");
    }

    componentWillUnmount() {
        //remove styles when component unmounts
        document.body.classList.remove("hold-transistion");
        document.body.classList.remove("sidebar-mini");
        document.body.classList.remove("layout-fixed");
        document.body.classList.remove("layout-navbar-fixed");
        document.body.classList.remove("layout-footer-fixed");
    }
    render() {
        const { articleList } = this.state;
        return (
            <Wrapper>
                <Nav />
                <SideMenu />
                <ContentWrapper>
                    <div className="content-header">
                        <HeaderBreadcrumb headerText="Delete Article" linkText="Dashboard" currentPage="Delete Article" />
                    </div>
                    <hr />
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <h3 className="card-title">List Of Post</h3>
                                        </div>
                                        <div className="card-body">
                                            <DataTables columns={columns}
                                                data={articleList}
                                                direction="auto"
                                                fixedHeaderScrollHeight="300px"
                                                pagination
                                                responsive
                                                striped
                                                subHeaderAlign="right"
                                                subHeaderWrap
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </ContentWrapper>
                <Footer />
                <aside className="control-sidebar control-sidebar-dark"></aside>
            </Wrapper>
        )
    }
}

export default withNavigateHook(DeleteArticle);