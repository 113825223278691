import React, { Component } from "react";
import { Link } from "react-router-dom";

class HyperLink extends Component {
    render() {
        return (
            <Link to={this.props.url} className={this.props.linkClass}>
                <i className={this.props.iconClass}></i>
                {this.props.children}
            </Link>
        )
    }
}

export default HyperLink;