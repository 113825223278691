import React, { Component } from 'react';
import Swal from 'sweetalert2';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import UploadAdapter from '../constants/uploadAdapter';

//component
import Wrapper from '../component/common/chunk/wrapper';
import ContentWrapper from "../component/common/chunk/contentWrapper";
import HeaderBreadcrumb from "../component/common/chunk/headerbreadcrumb";
import Footer from "../component/common/footer";
import Nav from "../component/common/nav";
import SideMenu from "../component/common/sidemenu";
//constants
import Endpoints from '../constants/endpoints';
import { VALIDATE_TOKEN } from '../constants/validation';
import withNavigateHook from '../component/withNavigateHook';

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timerProgressBar: true,
    timer: 5000,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
});

class CreateArticle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tags: [],
            previewImage: {},
            title: '',
            mnemonic: '',
            content: '',
            contentFull: '',
            searchContent: '',
            category: ''
        };
        this.previewImageRef = React.createRef();
    }

    authUser() {
        const userData = JSON.parse(localStorage.getItem('userData'));
        const token = localStorage.getItem('token');
        if (token === null || token === undefined || token.trim() === '') {
            this.props.navigation('/');
            window.location.reload(false);
        } else {
            if (userData) {
                VALIDATE_TOKEN(token, (valid) => {
                    if (valid.statusCode !== 0) {
                        localStorage.setItem('token', '');
                        this.props.navigation('/');
                        window.location.reload(false);
                    } else {
                        fetch(Endpoints.pos_tags, {
                            method: "GET",
                            headers: {
                                Authorization: 'Bearer ' + localStorage.getItem('token'),
                                Accept: "application/json"
                            }
                        })
                            .then((res) => res.json())
                            .then((postTags) => {
                                this.generateArticleMnemonic()
                                this.setState({ tags: postTags });
                            }).catch(err => {
                                console.log(`error fetching post tags -> ${err}`);
                            });
                    }
                });
            } else {
                localStorage.setItem('token', '');
                this.props.navigation('/');
                window.location.reload(false);
            }
        }
    }

    generateArticleMnemonic() {
        const mnemonic = Math.random().toString(36).slice(2);
        this.setState({ mnemonic: mnemonic });
    }

    resetPreviewImage() {
        this.previewImageRef.current.value = null;
    }

    handleSubmit(e) {
        e.preventDefault();
        const formData = new FormData();
        formData.append("previewImage", this.state.previewImage);
        formData.append('title', this.state.title);
        formData.append('mnemonic', this.state.mnemonic);
        formData.append('content', this.state.content);
        formData.append('content_full', this.state.contentFull);
        formData.append('category', this.state.category);
        formData.append('search_content', this.state.searchContent);

        fetch(Endpoints.create_article, {
            method: "POST",
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            },
            body: formData
        }).then((res) => res.json())
            .then((response) => {
                if (response.statusCode === 0) {
                    this.setState({
                        previewImage: {},
                        title: '',
                        mnemonic: '',
                        content: '',
                        contentFull: '',
                        searchContent: '',
                        category: ''
                    });
                    this.resetPreviewImage();
                    Toast.fire({
                        icon: 'success',
                        title: response.message
                    });
                    setTimeout(() => {
                        window.location.reload(false);
                    }, 5000);
                } else {
                    Toast.fire({
                        icon: 'error',
                        title: response.message
                    });
                }
            }).catch(err => {
                console.log(`error creating article -> ${err}`);
            });
    }

    componentDidMount() {
        //validate token
        this.authUser();

        // add class to body element
        document.body.classList.add("hold-transistion");
        document.body.classList.add("sidebar-mini");
        document.body.classList.add("layout-fixed");
        document.body.classList.add("layout-navbar-fixed");
        document.body.classList.add("layout-footer-fixed");
    }

    componentWillUnmount() {
        //remove styles when component unmounts
        document.body.classList.remove("hold-transistion");
        document.body.classList.remove("sidebar-mini");
        document.body.classList.remove("layout-fixed");
        document.body.classList.remove("layout-navbar-fixed");
        document.body.classList.remove("layout-footer-fixed");
    }

    render() {
        const { tags } = this.state;
        return (
            <Wrapper>
                <Nav />
                <SideMenu />
                <ContentWrapper>
                    <div className="content-header">
                        <HeaderBreadcrumb headerText="Create Blog Post" linkText="Dashboard" currentPage="Create Post" />
                    </div>
                    <hr />
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="text-center col-sm-12 col-md-12 col-lg-10 offset-lg-1">
                                    <div className="card card-primary card-outline">
                                        <div className="card-header">
                                            <h3 className="card-title text-center">Post Details</h3>
                                        </div>
                                        <form>
                                            <div className="card-body">
                                                <div className="form-group row">
                                                    <label htmlFor="postPreviewImage" className="col-sm-4 col-form-label float-right">Post Preview Image</label>
                                                    <div className="col-sm-8">
                                                        <input type="file"
                                                            ref={this.previewImageRef}
                                                            name="previewImage"
                                                            className="form-control form-control-sm"
                                                            placeholder="choose photo"
                                                            style={{ maxWidth: '500px' }}
                                                            onChange={e => { this.setState({ previewImage: e.target.files[0] }) }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label htmlFor="postName" className="col-sm-4 col-form-label float-right">Post Title</label>
                                                    <div className="col-sm-8">
                                                        <input type="text"
                                                            name='title'
                                                            className="form-control form-control-sm"
                                                            placeholder="Post Title"
                                                            required
                                                            style={{ maxWidth: '500px' }}
                                                            value={this.state.title}
                                                            onChange={e => { this.setState({ title: e.target.value }) }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label htmlFor="postMnemonic" className="col-sm-4 col-form-label float-right">Post Mnemonic</label>
                                                    <div className="col-sm-8">
                                                        <input type="text"
                                                            name='mnemonic'
                                                            className="form-control form-control-sm"
                                                            placeholder="Post Mnemonic"
                                                            required
                                                            readOnly
                                                            style={{ maxWidth: '500px' }}
                                                            value={this.state.mnemonic}
                                                            onChange={e => { this.setState({ mnemonic: e.target.value }) }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label htmlFor="postContent" className="col-sm-4 col-form-label float-right">Post Preview Content</label>
                                                    <div className="col-sm-8">
                                                        <textarea rows="5"
                                                            name='content'
                                                            className="form-control form-control-sm"
                                                            placeholder="Post Preview Content"
                                                            required
                                                            style={{ maxWidth: '500px' }}
                                                            value={this.state.content}
                                                            onChange={e => { this.setState({ content: e.target.value }) }}
                                                        >
                                                        </textarea>
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label htmlFor="postContentFull" className="col-sm-4 col-form-label float-right">Full Post Content</label>
                                                    <div className="col-sm-8">
                                                        <CKEditor
                                                            onReady={(editor) => {
                                                                editor.plugins.get('FileRepository')
                                                                    .createUploadAdapter = (loader) => {
                                                                        return new UploadAdapter(loader)
                                                                    }
                                                                editor.editing.view.change((writer) => {
                                                                    writer.setStyle(
                                                                        "height",
                                                                        "300px",
                                                                        editor.editing.view.document.getRoot()
                                                                    );
                                                                });
                                                            }}
                                                            editor={ClassicEditor}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                this.setState({ contentFull: data });
                                                            }}
                                                            onBlur={(event, editor) => { }}
                                                            onFocus={(event, editor) => { }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label htmlFor="postSearchContent" className="col-sm-4 col-form-label float-right">Post Search Content</label>
                                                    <div className="col-sm-8">
                                                        <input type="text"
                                                            name='search_content'
                                                            className="form-control form-control-sm"
                                                            placeholder="Post Key Tags"
                                                            required
                                                            style={{ maxWidth: '500px' }}
                                                            value={this.state.searchContent}
                                                            onChange={e => { this.setState({ searchContent: e.target.value }) }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label htmlFor="category" className="col-sm-4 col-form-label float-right">Post Category</label>
                                                    <div className="col-sm-8">
                                                        <select className="form-control form-control-sm"
                                                            name='category'
                                                            required="required"
                                                            style={{ maxWidth: '500px' }}
                                                            value={this.state.category}
                                                            onChange={e => { this.setState({ category: e.target.value }) }}
                                                        >
                                                            {tags.map((tag, k) => <option value={tag} key={k}>{tag}</option>)}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <div className="offset-sm-4 col-sm-8">
                                                        <button id="confirmButton" type="button"
                                                            className="btn btn-sm bg-gradient-primary btn-block"
                                                            style={{ maxWidth: '300px' }}
                                                            data-toggle="modal"
                                                            data-target="#confirmModal"
                                                        >
                                                            Create Post
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Confirmation Modal */}
                                            <div className="modal fade" id="confirmModal">
                                                <div className="modal-dialog">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h4 className="modal-title">TheMiddleStump - Confirm Post Details</h4>
                                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                                <span aria-hidden="true">&times;</span>
                                                            </button>
                                                        </div>
                                                        <div className="modal-body">
                                                            <div className="row">
                                                                <div className="col-sm-4 text-right">Post Title:</div>
                                                                <div className="col-sm-8 text-primary">{this.state.title}</div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-sm-4 text-right">Post Mnemonic:</div>
                                                                <div className="col-sm-8 text-primary">{this.state.mnemonic}</div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-sm-4 text-right">Post Preview Content:</div>
                                                                <div className="col-sm-8 text-primary">{this.state.content}</div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-sm-4 text-right">Post Search Content:</div>
                                                                <div className="col-sm-8 text-primary">{this.state.searchContent}</div>
                                                            </div>
                                                            <br />
                                                            <div className="row">
                                                                <div className="offset-sm-4 col-sm-8 text-primary">
                                                                    Confirm you want to create post
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="modal-footer justify-content-between">
                                                            <button type="button"
                                                                className="btn btn-block bg-gradient-secondary btn-sm btn-danger"
                                                                style={{ maxWidth: '120px' }}
                                                                data-dismiss="modal">
                                                                Close
                                                            </button>
                                                            <button type="submit"
                                                                className="btn btn-block bg-gradient-success btn-sm"
                                                                style={{ maxWidth: '120px' }}
                                                                name="action" value="edit"
                                                                onClick={e => { this.handleSubmit(e) }}
                                                                data-dismiss="modal"
                                                            >
                                                                Confirm
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </ContentWrapper>
                <Footer />
                <aside className="control-sidebar control-sidebar-dark"></aside>
            </Wrapper>
        )
    }
}

export default withNavigateHook(CreateArticle);