import React from "react";
import HyperLink from "./chunk/hyperlink";
import SideMenuListWrapper from "./chunk/sidemenulistwrapper";

export default function SideMenu() {
    return (
        <aside className="main-sidebar sidebar-dark-primary elevation-4">
            <a href="/" className="brand-link">
                <img src="/images/TheMiddleStumpNoBg.png"
                    alt="company Logo"
                    className="brand-image"
                />
            </a>

            <div className="sidebar">
                <div className="user-panel mt-2 pb-2 mb-2 d-flex">
                    <div className="info">
                        <h4 className="d-block text-center text-white">Admin Portal</h4>
                    </div>
                </div>
                <nav className="mt-2">
                    <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                        <SideMenuListWrapper listClass="nav-item has-treeview menu-open">
                            <HyperLink url="/home"
                                linkClass="nav-link active"
                                iconClass="nav-icon fas fa-tachometer-alt"
                            >
                                <p>Dashboard</p>
                                <i className="right fas fa-angle-left"></i>
                            </HyperLink>
                        </SideMenuListWrapper>
                        <SideMenuListWrapper listClass="nav-item has-treeview">
                            <HyperLink url="#" linkClass="nav-link" iconClass="nav-icon fas fa-cogs">
                                <p>Post Management</p>
                                <i className="right fas fa-angle-left"></i>
                            </HyperLink>
                            <ul className="nav nav-treeview">
                                <SideMenuListWrapper listClass="nav-item">
                                    <HyperLink url="/post/create"
                                        linkClass="nav-link"
                                        iconClass="fas fa-newspaper nav-icon"
                                    >
                                        <p>New Post</p>
                                    </HyperLink>
                                </SideMenuListWrapper>
                                <SideMenuListWrapper listClass="nav-item">
                                    <HyperLink url="#"
                                        linkClass="nav-link"
                                        iconClass="fas fa-edit nav-icon"
                                    >
                                        <p>Edit Post</p>
                                    </HyperLink>
                                </SideMenuListWrapper>
                            </ul>
                        </SideMenuListWrapper>
                        <SideMenuListWrapper listClass="nav-item has-treeview">
                            <HyperLink url="#" linkClass="nav-link" iconClass="nav-icon fas fa-chart-pie">
                                <p>Campaigns</p>
                                <i className="right fas fa-angle-left"></i>
                            </HyperLink>
                            <ul className="nav nav-treeview">
                                <SideMenuListWrapper listClass="nav-item">
                                    <HyperLink url="/send/newsletter"
                                        linkClass="nav-link"
                                        iconClass="fas fa-chart-line nav-icon"
                                    >
                                        <p>Send Newsletter</p>
                                    </HyperLink>
                                </SideMenuListWrapper>
                            </ul>
                        </SideMenuListWrapper>
                        <SideMenuListWrapper listClass="nav-item has-treeview">
                            <HyperLink url="#"
                                linkClass="nav-link"
                                iconClass="nav-icon fas fa-chart-line"
                            >
                                <p>Trivias</p>
                                <i className="right fas fa-angle-left"></i>
                            </HyperLink>
                            <ul className="nav nav-treeview">
                                <SideMenuListWrapper listClass="nav-item">
                                    <HyperLink url="#"
                                        linkClass="nav-link"
                                        iconClass="nav-icon fas fa-chart-line"
                                    >
                                        <p>Quiz Promotions</p>
                                    </HyperLink>
                                </SideMenuListWrapper>
                                <SideMenuListWrapper listClass="nav-item">
                                    <HyperLink url="#"
                                        linkClass="nav-link"
                                        iconClass="nav-icon fas fa-chart-line"
                                    >
                                        <p>Readers Poll</p>
                                    </HyperLink>
                                </SideMenuListWrapper>
                            </ul>
                        </SideMenuListWrapper>
                        <SideMenuListWrapper listClass="nav-item has-treeview">
                            <HyperLink url="#" linkClass="nav-link" iconClass="nav-icon fas fa-chart-pie" >
                                <p>Analytics</p>
                                <i className="right fas fa-angle-left"></i>
                            </HyperLink>
                            <ul className="nav nav-treeview">
                                <SideMenuListWrapper listClass="nav-item">
                                    <HyperLink url="/admin/analytics"
                                        linkClass="nav-link"
                                        iconClass="nav-icon fas fa-chart-line"
                                    >
                                        <p>Website Analytics</p>
                                    </HyperLink>
                                </SideMenuListWrapper>
                            </ul>
                        </SideMenuListWrapper>
                    </ul>
                </nav>
            </div>
        </aside>
    )
}