import React from "react";

export default function Footer() {
    return (
        <footer className="main-footer">
            <div className="float-right d-none d-sm-block">
                <b>Version</b> 1.0.0
            </div>
            <strong>Copyright &copy;
                <script>document.write(new Date().getFullYear());</script>
                <a href="https://www.themiddlestump.xyz">themiddlestump.xyz</a>.
            </strong>
            All rights reserved.
        </footer>
    )
}