import React, { Component } from "react";
import Swal from 'sweetalert2';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
//constants
import Endpoints from '../constants/endpoints';
import { VALIDATE_TOKEN } from '../constants/validation';
import UploadAdapter from "../constants/uploadAdapter";
//component
import withNavigateHook from '../component/withNavigateHook';
import Wrapper from "../component/common/chunk/wrapper";
import Nav from "../component/common/nav";
import SideMenu from "../component/common/sidemenu";
import ContentWrapper from "../component/common/chunk/contentWrapper";
import HeaderBreadcrumb from "../component/common/chunk/headerbreadcrumb";
import Footer from "../component/common/footer";

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timerProgressBar: true,
    timer: 5000,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
});

class Newsletter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: '',
            contentFull: ''
        };
    }

    authUser() {
        const userData = JSON.parse(localStorage.getItem('userData'));
        const token = localStorage.getItem('token');
        if (token === null || token === undefined || token.trim() === '') {
            this.props.navigation('/');
            window.location.reload(false);
        } else {
            if (userData) {
                VALIDATE_TOKEN(token, (valid) => {
                    if (valid.statusCode !== 0) {
                        localStorage.setItem('token', '');
                        this.props.navigation('/');
                        window.location.reload(false);
                    }
                });
            } else {
                localStorage.setItem('token', '');
                this.props.navigation('/');
                window.location.reload(false);
            }
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        const body = JSON.stringify({
            title: this.state.title,
            contentFull: this.state.contentFull
        });

        fetch(Endpoints.create_newsletter_queue, {
            method: "POST",
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token'),
                Accept: "application/json",
                'Content-Type': "application/json"
            },
            body: body
        }).then((res) => res.json())
            .then((response) => {
                if (response.statusCode === 0) {
                    this.setState({
                        title: '',
                        contentFull: ''
                    });
                    Toast.fire({
                        icon: 'success',
                        title: response.message
                    });
                    setTimeout(() => {
                        window.location.reload(false);
                    }, 5000);
                } else {
                    Toast.fire({
                        icon: 'error',
                        title: response.message
                    });
                }
            }).catch(err => {
                console.log(`error creating article -> ${err}`);
            });
    }

    componentDidMount() {
        //validate token
        this.authUser();

        // add class to body element
        document.body.classList.add("hold-transistion");
        document.body.classList.add("sidebar-mini");
        document.body.classList.add("layout-fixed");
        document.body.classList.add("layout-navbar-fixed");
        document.body.classList.add("layout-footer-fixed");
    }

    componentWillUnmount() {
        //remove styles when component unmounts
        document.body.classList.remove("hold-transistion");
        document.body.classList.remove("sidebar-mini");
        document.body.classList.remove("layout-fixed");
        document.body.classList.remove("layout-navbar-fixed");
        document.body.classList.remove("layout-footer-fixed");
    }

    render() {
        return (
            <Wrapper>
                <Nav />
                <SideMenu />
                <ContentWrapper>
                    <div className="content-header">
                        <HeaderBreadcrumb
                            headerText="Create Newsletter"
                            linkText="Dashboard"
                            currentPage="Create Newsletter"
                        />
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="text-center col-sm-12 col-md-12 col-lg-10 offset-lg-1">
                                    <div className="card card-primary card-outline">
                                        <div className="card-header">
                                            <h3 className="card-title text-center">Newsletter Details</h3>
                                        </div>
                                        <form>
                                            <div className="card-body">
                                                <div className="form-group row">
                                                    <label htmlFor="title"
                                                        className="col-sm-2 col-form-label float-right">
                                                        Title:
                                                    </label>
                                                    <div className="col-sm-10">
                                                        <input type="text"
                                                            className="form-control form-control-sm"
                                                            placeholder="Article Title"
                                                            required
                                                            style={{ maxWidth: '650px' }}
                                                            value={this.state.title}
                                                            onChange={e => { this.setState({ title: e.target.value }) }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label htmlFor="contentFull"
                                                        className="col-sm-2 col-form-label float-right">
                                                        Content:
                                                    </label>
                                                    <div className="col-sm-10">
                                                        <CKEditor
                                                            onReady={(editor) => {
                                                                editor.plugins.get('FileRepository')
                                                                    .createUploadAdapter = (loader) => {
                                                                        return new UploadAdapter(loader)
                                                                    }
                                                                editor.editing.view.change((writer) => {
                                                                    writer.setStyle(
                                                                        "height",
                                                                        "300px",
                                                                        editor.editing.view.document.getRoot()
                                                                    );
                                                                });
                                                            }}
                                                            editor={ClassicEditor}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                this.setState({ contentFull: data });
                                                            }}
                                                            onBlur={(event, editor) => { }}
                                                            onFocus={(event, editor) => { }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <div className="offset-sm-2 col-sm-8">
                                                        <button id="confirmButton" type="button"
                                                            className="btn btn-sm bg-gradient-primary btn-block"
                                                            style={{ maxWwidth: '300px' }}
                                                            data-toggle="modal"
                                                            data-target="#confirmModal">
                                                            Send Newsletter&nbsp;&nbsp;
                                                            <i className="fas fa-paper-plane"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Confirmation Modal */}
                                            <div className="modal fade" id="confirmModal">
                                                <div className="modal-dialog">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h4 className="modal-title">TheMiddleStump - Confirm Newsletter</h4>
                                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                                <span aria-hidden="true">&times;</span>
                                                            </button>
                                                        </div>
                                                        <div className="modal-body">
                                                            <div className="row">
                                                                <div className="col-sm-4 text-right">Title:</div>
                                                                <div className="col-sm-8 text-primary">{this.state.title}</div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-sm-4 text-right">Newsletter Content:</div>
                                                                <div className="col-sm-8 text-primary">{this.state.contentFull.substring(0, 141).replace('<p>', '').replace('</p>', '')}</div>
                                                            </div>
                                                            <br />
                                                            <div className="row">
                                                                <div className="offset-sm-4 col-sm-8 text-primary">
                                                                    Confirm Newsletter content?
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="modal-footer justify-content-between">
                                                            <button type="submit"
                                                                className="btn btn-block bg-gradient-success btn-sm"
                                                                style={{ maxWidth: '120px' }}
                                                                onClick={e => { this.handleSubmit(e) }}
                                                                data-dismiss="modal"
                                                            >
                                                                Confirm
                                                            </button>
                                                            <button type="button"
                                                                className="btn btn-block bg-gradient-secondary btn-sm btn-danger"
                                                                style={{ maxWidth: '120px' }}
                                                                data-dismiss="modal"
                                                            >
                                                                Close
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </ContentWrapper>
                <Footer />
                <aside className="control-sidebar control-sidebar-dark"></aside>
            </Wrapper>
        )
    }
}

export default withNavigateHook(Newsletter);