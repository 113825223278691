import React from "react";

export default function HeaderBreadcrumb(props) {
    return (
        <div className="container-fluid">
            <div className="row mb-2">
                <div className="col-sm-6">
                    <h1 className="m-0 text-dark">{props.headerText}</h1>
                </div>
                <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item"><a href="/dashboard">{props.linkText}</a></li>
                        <li className="breadcrumb-item active">{props.currentPage}</li>
                    </ol>
                </div>
            </div>
        </div>
    );
}